form label {
    font-size: 14px;
}

.border-custom::after {
    content: '';
    display: block; /* Asegura que el pseudo-elemento sea visible */
    border-bottom: 2px solid black; /* Aplica la línea inferior */
    width: 100%; /* Define el ancho relativo al contenedor padre */
    margin: 0 auto; /* Centra la línea horizontalmente */
}

.summary {
    margin-left: 30px !important;
}

@media screen and (max-width: 768px) {
    div#carrito > div:nth-child(2) > div > div:nth-child(2) {
        order: 1;
    }
    div#carrito > div:nth-child(2) > div > div:nth-child(1) {
        order: 2;
    }

    div#carrito>div.mt-2>div {
        flex-direction: column;
    }

    div.summary {
        margin: 0 !important;
    }

    div.discont {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    input#discount {
        margin-bottom: 15px;
    }
    div.border-custom {
        margin-left: 0;
    }
    .border-custom {
        text-align: center;
    }
}